<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>意向信息</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="wzright" style="float:right; margin-top:-20px;">
                <!-- <el-button size="small" @click="gotoAdd">+添加信息</el-button> -->
            </div>
        </div>
        <div class="box" style="margin-top:30px;">
            <div class="search">
                <div class="searchleft">
                    <el-form :inline="true" :model="searchfrom" class="demo-form-inline">
                        <el-form-item label="日期">
                            <el-date-picker
                            v-model="searchfrom.time"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search"  @click="onSubmit" class="mybotton">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="searchright">
                    <el-upload
                    class="upload-demo"
                    action="/api/registrationIntention/importFile"
                    :limit="1"
                    ref='upload'
                    :show-file-list="false"
                    :before-upload="beforeUpload"
                    :headers="headers"
                    :on-success="onSuccess">
                    <el-button  type="primary" icon="el-icon-upload2" style="margin-right:8px;"  :loading="uploading">导入信息</el-button>
                    </el-upload>
                    <el-button type="primary"  @click="exportaction" icon="el-icon-bottom" :loading="exportloading">导出</el-button>
                    <el-button  type="primary" class="mybotton" icon="el-icon-circle-plus-outline" @click="openadd">添加信息</el-button>
                </div>
            </div>
            <div class="list">
                <el-table
                :data="list"
                border
                :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                stripe
                style="width: 100%">
                    <el-table-column
                    prop="userName"
                    label="求职人">
                    </el-table-column>
                    <el-table-column
                    prop="phone"
                    label="联系方式">
                    </el-table-column>
                    <el-table-column
                    prop="workUnit"
                    label="达成工作单位">
                    </el-table-column>
                    <el-table-column
                    prop="isSatisfied"
                    label="对岗位是否满意">
                        <template slot-scope="scope">
                            <el-tag type="success"  v-if="scope.row.isSatisfied">满意</el-tag>
                            <el-tag type="warning" v-if="!scope.row.isSatisfied">不满意</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="createTime"
                    label="日期">
                    </el-table-column>
                    <el-table-column
                    prop="address"
                    width="200"
                    label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary"  @click="openedit(scope.row)">编辑</el-button>
                            <el-button type="danger"  @click="delbooth(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <app-page :total="total" :current="pageNo" :pageSize="pageSize" @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
        </div>
        <pop-up :title="status==1?'添加':'编辑'" width="35%" :isshow="showpop" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">

                <el-form ref="form" :model="form" label-width="120px">
                    <el-form-item label="求职人">
                        <el-input v-model="form.userName"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话">
                        <el-input v-model="form.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="达成工作单位">
                        <el-input v-model="form.workUnit"></el-input>
                    </el-form-item>
                    <el-form-item label="对岗位是否满意">
                        <el-radio v-model="form.isSatisfied" :label="true">满意</el-radio>
                        <el-radio v-model="form.isSatisfied" :label="false">不满意</el-radio>
                    </el-form-item>
                    <el-form-item label="日期">
                        <el-date-picker
                        v-model="form.createTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
import serverurl from '@/api/booth'
import _api from '@/api/index'
import axios from "axios";
export default {
    data(){
        return{
            headers:{
                AuthorToken:JSON.parse(window.localStorage.getItem('token'))
            },
            searchfrom:{time:null},
            form:{id:null,userName:null,phone:null,workUnit:null,isSatisfied:true,createTime:null},
            list:[],
            total:0,
            status:1,
            pageNo:1,
            pageSize:20,
            loading:false,
            showpop:false,
            s:null,
            currdate:null,
            uploading:false,
            exportloading:false,
        }
    },
    created(){
        var date = new Date()
        var month = parseInt(date.getMonth() + 1)
        var day = date.getDate()
        var day2 = date.getDate()-1
        if (month < 10)  month = '0' + month
        if (day < 10)  day = '0' + day
        if (day2 < 10)  day2 = '0' + day2
        this.currdate = date.getFullYear() + '-' + month + '-' + day
        this.form.createTime=this.currdate
        this.s=this.$route.query.s
        // console.log(this.s)
        if(this.s!=null && this.s!=undefined && this.s!=''){
            this.searchfrom.time=date.getFullYear() + '-' + month + '-' + day2
        }

        this.getList()
    },
    methods:{
        timestampToTime(timestamp) {
            var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
            var D = date.getDate() + ' ';
            var h = date.getHours() + ':';
            var m = date.getMinutes() + ':';
            var s = date.getSeconds();
            return Y+M+D+h+m+s;
        },
        exportaction(){//导出


            this.exportloading=true
            let token = JSON.parse(window.localStorage.getItem('token'))
            axios.post("/api/"+serverurl.exportRegistration, {},{
                headers: {
                    'Content-Type': 'application/json',
                    'AuthorToken':token
                },
                responseType: 'blob'
            }).then(res=>{
                console.log(res)
                var blob = new Blob([res.data]);
                this.exportloading=false
                var downloadElement = document.createElement('a');
                var href = window.URL.createObjectURL(blob); //创建下载的链接
                downloadElement.href = href;
                let timestr=new Date().getTime()/1000
                downloadElement.download = '意向信息'+this.timestampToTime(timestr) + '.xlsx'; //下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
            })
        },
        onSuccess(response, file, fileList){
            this.uploading=false
            console.log(response)
            this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
            if(response.code==200){
                this.$message.success("导入成功！")
                this.getList()
            }
        },
        beforeUpload(file){
            this.uploading=true
            return true
        },
        getList(){
            this.loading=true
            _api.get(serverurl.registrationIntention,{page:this.pageNo,size:this.pageSize,...this.searchfrom}).then(res=>{
                this.loading=false
                console.log(res)
                if(res.code==200){
                    this.list=res.data.list
                    this.total=res.data.total
                }
            })
        },
        delbooth(row){

            this.$confirm('确定要删除吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                _api.delete(serverurl.registrationIntention,row.id).then(res=>{
                console.log(res)
                    if(res.code==200){
                        this.$message.success("删除成功！")
                        this.getList()
                    }
                })
            }).catch(() => {

            });


        },
        openedit(row){
            for(let k in this.form){
                this.form[k]=row[k]
            }
            // this.form.createTime=this.form.createTime.substring(0,10)
            this.status=2
            this.showpop=true
        },
        openadd(){
            this.status=1
            this.showpop=true
        },
        getconfirm(){
            let apitype=this.status==1?'post':'put'
            _api[apitype](serverurl.registrationIntention,this.form).then(res=>{
                console.log(res)
                if(res.code==200){
                    this.$message.success("操作成功！")
                    this.getclose()
                    this.getList()
                }else{
                    this.$message.warning(res.message)
                }
            })
        },
        getclose(){
            this.showpop=false
            this.status=1
            this.form={id:null,userName:null,phone:null,workUnit:null,isSatisfied:true,createTime:null}
            this.form.createTime=this.currdate
        },
        onSubmit(){
            this.pageNo=1
            this.getList()
        },
        handchange(data){//分页Size变化
            this.pageSize=data
            this.pageNo=1
            this.getList()
        },
        currentchange(data){//当前页变化
            console.log(data)
            this.pageNo=data
            this.getList()
        },
    }
}
</script>
<style scoped>
.searchright{ display: flex; align-items: center;}
</style>
